import * as React from "react";

const SVGCP1 = (props) => (
	<svg
		width={754}
		height={808}
		viewBox="0 0 754 808"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g opacity={0.1}>
			<circle
				cx={11.9995}
				cy={742}
				r={520.816}
				stroke="#999A9C"
				strokeWidth={90.3501}
			/>
			<circle
				cx={12}
				cy={742}
				r={696.825}
				stroke="#00A8E4"
				strokeWidth={90.3501}
			/>
		</g>
	</svg>
);

export default SVGCP1;
