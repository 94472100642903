import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import { GatsbyImage } from "gatsby-plugin-image";
import ServiceHeroMobile from "../components/service-hero-mobile";
import SVGCP1 from "../components/svg/cp1-svg";
import SVGSC3 from "../components/svg/sc3-svg";

const ContactPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image-HP" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CUPImg: wpMediaItem(title: { eq: "Contact-Us-Image-CUP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const CUPImg = data.CUPImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<div className="position-relative">
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
					{/* <script
						src="https://www.google.com/recaptcha/api.js"
						async
						defer
					></script> */}
				</Helmet>
				<GatsbySeo
					title="Contact Us | Colston Ltd"
					description="Contact us page"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/contact-us`,
						title: "Contact Us | Colston Ltd",
						description: "Contact us page",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<SVGSC3
					style={{ zIndex: "-1", bottom: "-60rem" }}
					className="position-absolute d-none d-lg-block  start-0"
				/>
				<section className=" ">
					<ServiceHeroMobile
						title="Contact"
						description={<ContactForm />}
						ctaText={null}
					/>
					<Container className="d-lg-none">
						<Row>
							<Col className="text-center mb-5">
								<a
									className="link-primary fs-5  mt-5 d-block"
									href="mailto:office@colston.ltd.uk"
								>
									office@colston.ltd.uk
								</a>

								<a
									className="link-primary d-block mt-4 fs-5"
									href="tel:+441323700306"
								>
									01323 700 306
								</a>
								<a
									className="link-primary d-block mt-4 fs-5"
									target="_blank"
									rel="noreferrer"
									href="https://www.google.com/maps?ll=50.776049,0.266754&z=18&t=m&hl=en&gl=GB&mapclient=embed&cid=116475131763585398"
								>
									2 Willingdon Rd
									<br /> Eastbourne
									<br /> East Sussex
									<br /> BN21 1TH
								</a>
							</Col>
						</Row>
					</Container>
					<Container
						className="position-relative d-none d-lg-block py-0 my-0 px-0 mx-0"
						fluid
					>
						<div className="position-absolute top-0 w-100">
							<Navigation />
						</div>

						<Row className="mx-0 px-0  h-100">
							<Col
								style={{ marginTop: "11vh" }}
								className="pt-8 d-flex ps-xl-8 ps-lg-4 "
								lg={4}
							>
								<div className="d-block">
									<h1 className="text-primary display-4 fw-bold">Contact</h1>
									<hr className="bg-secondary  hr-big w-80 " />
									<a
										className="link-primary fs-5  mt-5 d-block"
										href="mailto:office@colston.ltd.uk"
									>
										office@colston.ltd.uk
									</a>

									<a
										className="link-primary d-block mt-lg-4 fs-5"
										href="tel:+441323700306"
									>
										01323 700 306
									</a>
									<a
										className="link-primary d-block mt-4 fs-5"
										target="_blank"
										rel="noreferrer"
										href="https://www.google.com/maps?ll=50.776049,0.266754&z=18&t=m&hl=en&gl=GB&mapclient=embed&cid=116475131763585398"
									>
										2 Willingdon Rd
										<br /> Eastbourne
										<br /> East Sussex
										<br /> BN21 1TH
									</a>
								</div>
							</Col>
							<Col className="px-0 mx-0" lg={8}>
								<div
									style={{
										paddingTop: "11vh",
										borderTopLeftRadius: "8vh",
										borderBottomRightRadius: "8vh",
									}}
									className="bg-primary     text-white "
								>
									<Row className="pt-6 justify-content-end px-0 mx-0 ">
										<Col lg={6}>
											<h2 className="fw-light">Contact us</h2>
											<hr className="bg-secondary mb-5 hr-big w-50 " />

											<ContactForm />
										</Col>
										<Col className="text-end pt-6 pe-5 " lg={5}>
											<div
												style={{ maxWidth: "18rem", height: "23rem" }}
												className="bg-secondary left-rounded d-lg-none d-xl-block position-relative ms-auto"
											>
												<div
													style={{ top: "-1.5rem", right: "1.5rem" }}
													className="position-absolute left-rounded "
												>
													<GatsbyImage
														style={{ width: "18rem", height: "23rem" }}
														className="left-rounded"
														image={CUPImg}
														alt={data.CUPImg?.altText}
													/>
												</div>
											</div>
											<div
												style={{ maxWidth: "12rem", height: "20rem" }}
												className="bg-secondary left-rounded d-xl-none position-relative mx-auto"
											>
												<div
													style={{ top: "-1.5rem", right: "1.5rem" }}
													className="position-absolute left-rounded "
												>
													<GatsbyImage
														style={{ width: "12rem", height: "20rem" }}
														className="left-rounded"
														image={CUPImg}
														alt={data.CUPImg?.altText}
													/>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1225.5735530264024!2d0.2653334638033428!3d50.776108763821405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df73f24e08aad7%3A0x19dcd83e5ef7176!2sColston!5e0!3m2!1sen!2suk!4v1647962675651!5m2!1sen!2suk"
						width="100%"
						height="600"
						style="border:0;"
						allowfullscreen=""
						loading="lazy"
					></iframe>
				</section>
			</div>
		</Layout>
	);
};

export default ContactPage;
